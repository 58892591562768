<script setup lang="ts">
import { Card, Icon } from 'webcc-ui-components'
import AccessibleIconButton from '~/components/basic/AccessibleIconButton.vue'

withDefaults(
  defineProps<{
    prefixIcon?: string
    suffixIcon?: string
    disabled?: boolean
    ariaLabelIcon?: string
    contentClass?: string | null
  }>(),
  {
    prefixIcon: undefined,
    suffixIcon: 'arrow-right',
    ariaLabelIcon: undefined,
    disabled: false,
    contentClass: null,
  },
)

const emit = defineEmits<{
  (e: 'click'): void
}>()

function handleClick() {
  emit('click')
}

function handleKeyDown(evt: KeyboardEvent) {
  if (evt.code === 'Enter') {
    emit('click')
  }
}
</script>

<template>
  <Card
    class="[&&]:p-4"
    :class="{
      '[&&]:cursor-not-allowed': disabled,
    }"
    :background-class="disabled ? 'bg-bgr-100' : 'bg-bgr'"
    role="button"
    :tabindex="disabled ? null : 0"
    :aria-disabled="disabled"
    v-on="disabled ? {} : { click: handleClick, onKeyDown: handleKeyDown }"
  >
    <div class="w-full flex gap-x-4 items-center">
      <slot name="prefix-icon">
        <Icon v-if="prefixIcon" size="lg" :graphic="prefixIcon" />
      </slot>

      <div class="w-full flex flex-col gap-y-1" :class="contentClass">
        <slot />
      </div>

      <slot name="suffix-icon">
        <AccessibleIconButton
          v-if="suffixIcon"
          :disabled="disabled"
          :label="ariaLabelIcon"
          :graphic="suffixIcon"
        />
      </slot>
    </div>
  </Card>
</template>
